'use client';

import { PopUpDisclaimer } from '@vcc-package/overlays';
import ResponsiveGridHelper from '@vcc-www/responsive-grid-helper';
import React from 'react';
import { ExtendCSS, Inline, Text } from 'vcc-ui';
import { Disclaimer } from '../disclaimer';
import { Markdown } from '../markdown';
import Props from './TextStatement.props';

const TextStatement: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  text,
  body,
  description,
  breakLine,
  disclaimer,
  titleElement = 'p',
  alignContent = 'center',
  titleExtend,
  textExtend,
  bodyVariant = 'hillary',
  innerWrapperExtend,
  outerWrapperExtend,
  size = 7,
  withInlinePopUpDisclaimer,
  trackLabel,
  titleVariant = 'ootah',
  isLargeBodyText = false,
}) => {
  return (
    <ResponsiveGridHelper
      size={size}
      extend={[responsiveGridHelperCSS(alignContent), innerWrapperExtend]}
      extendOuterWrapper={[
        { justifyContent: alignContent },
        outerWrapperExtend,
      ]}
    >
      {!!withInlinePopUpDisclaimer && withInlinePopUpDisclaimer.description ? (
        <PopUpDisclaimer
          title={withInlinePopUpDisclaimer.title}
          description={withInlinePopUpDisclaimer.description}
          trackLabel={trackLabel}
          addMarginInlineStart
        >
          {(icon) => (
            <Text
              variant={titleVariant}
              as={titleElement}
              subStyle="emphasis"
              extend={[wrapperCSS({ alignContent }), titleExtend]}
              data-testid="text-statement-wrapper"
            >
              {title && <Inline extend={{ display: 'inline' }}>{title}</Inline>}
              {breakLine ? <br /> : ' '}
              <Inline extend={[textCSS, textExtend]}>
                {text}
                {icon}
              </Inline>
            </Text>
          )}
        </PopUpDisclaimer>
      ) : (
        <Text
          variant={titleVariant}
          as={titleElement}
          subStyle="emphasis"
          extend={[wrapperCSS({ alignContent }), titleExtend]}
          data-testid="text-statement-wrapper"
        >
          {title && <Inline extend={{ display: 'inline' }}>{title}</Inline>}
          {breakLine ? <br /> : ' '}
          <Inline extend={[textCSS, textExtend]}>{text}</Inline>
        </Text>
      )}

      {body && (
        <Text
          variant={isLargeBodyText ? 'hillary' : bodyVariant}
          foreground="foreground.secondary"
        >
          {body}
        </Text>
      )}
      {description && (
        <Markdown
          className={`${isLargeBodyText ? 'font-20' : 'font-16'} text-secondary mt-16`}
          markdown={description}
        />
      )}
      {!!disclaimer && (
        <div className="pt-16 flex-row justify-center">
          <Disclaimer
            extend={alignDisclaimerTextCSS(alignContent)}
            text={disclaimer}
          />
        </div>
      )}
    </ResponsiveGridHelper>
  );
};

export default TextStatement;

const responsiveGridHelperCSS =
  (alignContent: 'left' | 'center' | 'center-left'): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    untilM: {
      paddingLeft: baselineGrid * 3,
      paddingRight: baselineGrid * 3,
    },
    ...(alignContent === 'left' && { margin: 0 }),
  });

const wrapperCSS =
  ({
    alignContent,
  }: {
    alignContent: 'left' | 'center' | 'center-left';
  }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    textAlign:
      alignContent === 'center' || alignContent === 'center-left'
        ? 'center'
        : 'left',
    padding: `${baselineGrid * 2}px 0`,
    untilM: {
      textAlign: alignContent === 'center-left' ? 'center' : 'left',
    },
  });

const alignDisclaimerTextCSS = (
  alignContent: 'left' | 'center' | 'center-left',
): ExtendCSS => ({
  fromM: {
    alignSelf: alignContent === 'center' ? 'center' : 'left',
    textAlign: alignContent === 'center' ? 'center' : 'left',
  },
});

const textCSS: ExtendCSS = ({ theme: { color } }) => ({
  whiteSpace: 'pre-line',
  color: color.foreground.secondary,
});
